import { useNavigate } from "react-router-dom"

function LoginPage() {
    const navigate = useNavigate();

    const handleLogin = () => {
        // fetch('http://localhost:3001/login', {
        fetch('https://zigzagoon.mydns.jp:3001/login', {
            method: 'GET',
            credentials: 'include' // クッキーを送信するために必要
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data.message);
                // 認証に成功したらページを遷移する
                navigate('/DMpage');
            })
            .catch(error => {
                console.error('There was a problem with your fetch operation:', error);
            });
    };

    return (
        <div>
            <h1>Welcome to the Login Page</h1>
            <button onClick={handleLogin}>Login</button>
        </div>
    );
}
export default LoginPage