function TableDataMake(mainData, checkData) {

    let customTable;

    //ループ処理
    customTable = mainData.map((elem) => {
        let cardData = elem;
        const searchData = checkData.find(d => {
            return d.card_id === cardData.card_id;
        });

        if (searchData === undefined) {
            checkData.push({
                "card_id": elem.card_id,
                "card_num": 0
            })
            console.log(checkData);
            elem['card_number'] =0;

        }
        else {
            // console.log(searchData.card_num);
            elem['card_number'] = searchData.card_num;
        }

        return cardData;
    });


    // customTable=mainData;

    return (

        customTable

    );
}

export default TableDataMake;