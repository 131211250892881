import React from 'react'
import Table from '../Table'
import CardJS from '../card_list.json'
import CardPossessions from '../card_possessions.json'

const DMPage = () => {
    // const navigate = useNavigate()
    return (
        <Table list={CardJS} card_possessions={CardPossessions}/>
    )
}
export default DMPage