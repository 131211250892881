import { BrowserRouter, Routes, Route } from "react-router-dom";
import LogPage from "./component/LoginPage";
import DM from "./component/DMPage";

const Routers = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LogPage/>}/>
                <Route path="/DMpage" element={<DM/>}/>
            </Routes>
        </BrowserRouter>
    )
}
export default Routers