import {
    React,
    useMemo,
} from 'react'
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import TableDataMake from './TableDataMake';


function Table({ list ,card_possessions}) {

    // const data = list;      //JSON格納

    const data= useMemo(() => TableDataMake(list,card_possessions),[list,card_possessions]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'card_number',
                header: '所持数',
                size: 10,
            },
            {
                accessorKey: 'card_name',
                header: 'カード名',
                size: 250,
            },
            {
                accessorKey: 'card_cost',
                header: 'コスト',
                size: 10,
            },
            {
                accessorKey: 'card_civilization',
                header: '文明',
                size: 100,
            },
            {
                accessorKey: 'card_type',
                header: '種類',
                size: 100,
            },

            {
                accessorKey: 'card_race',
                header: '種族',
                size: 150,
            },
            {
                accessorKey: 'card_power',
                header: 'パワー',
                size: 80,
            },

        ],
        [],
    );


    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnFilterModes: true,  //フィルタの種類を追加
        enableColumnOrdering: true,     //カラムの並べかえを許可
        enableEditing: true,            //セルの編集を許可
        enableColumnPinning: true,      //カラムのピン留め許可
        enableRowActions: true,         //行アクションを許可
        initialState: { density: 'compact', showColumnFilters: true, showGlobalFilter: true },
        muiTableBodyCellProps: {
            sx: {
                border: '1px solid rgba(81, 81, 81, .5)',
            },
        },
        layoutMode: 'grid-no-grow',
    });

    return <MaterialReactTable table={table} />;


}

export default Table;
